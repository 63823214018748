import { render, staticRenderFns } from "./orderWithdrawalRecord-list.vue?vue&type=template&id=3642cd3d&scoped=true&"
import script from "./orderWithdrawalRecord-list.vue?vue&type=script&lang=js&"
export * from "./orderWithdrawalRecord-list.vue?vue&type=script&lang=js&"
import style0 from "../../assets/merchantClient/css/admin.scss?vue&type=style&index=0&id=3642cd3d&prod&lang=scss&scoped=true&"
import style1 from "./orderWithdrawalRecord-list.vue?vue&type=style&index=1&id=3642cd3d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3642cd3d",
  null
  
)

export default component.exports